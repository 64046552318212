<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :stopClickOutside="true"
    :titleText="$t('noficationWindow.notifications')"
  >
    <div class="notification">
      <div class="notification-header">
        <div
          class="notification-header-item"
          :class="currentPage === 'invitation' ? 'profile-sprite tab1' : ''"
          @click="currentPage = 'invitation'"
        >
          <span>{{ $t("noficationWindow.tableInvite") }}</span>
          <span v-if="currentPage === 'invitation'" class="slot-line"></span>
        </div>
        <!-- <div
          class="notification-header-item"
          :class="currentPage === 'system-message' ? 'profile-sprite tab2' : ''"
          @click="currentPage = 'system-message'"
        >
          <span>
            {{ $t("noficationWindow.system") }} <br />
            {{ $t("noficationWindow.messages") }}</span
          >
          <span
            v-if="currentPage === 'system-message'"
            class="slot-line"
          ></span>
        </div>
        <div
          class="notification-header-item"
          :class="currentPage === 'contact-us' ? 'profile-sprite tab2' : ''"
          @click="currentPage = 'contact-us'"
        >
          <span> {{ $t("inboxContact.contactUs") }}</span>
          <span v-if="currentPage === 'contact-us'" class="slot-line"></span>
        </div> -->
      </div>
      <div
        class="notification-content bg-content-red"
        v-if="currentPage === 'invitation'"
      >
        <div class="sprite-profile-popup line"></div>
        <NotificationItem
          v-for="(invite, index) in getInviteList"
          :key="index"
          :invite="invite"
          :index="index"
          @action="joinGameFunc"
        />
        <div
          class="invitation-no-data flex-center"
          v-if="getInviteList.length < 1"
        >
          <p class="invitation-no-data-text">
            {{ $t("notification.noData") }}
          </p>
        </div>
      </div>
      <div
        class="system-message bg-content-red"
        v-if="currentPage === 'system-message'"
      >
        <div class="system-message-left bg-dark-red">
          <div
            class="system-message-left-item br-15"
            v-for="number in 10"
            :key="number"
          >
            <span class="sprite-profile-popup small-message-icon"></span>
            <span class="system-message-left-item-text">{{
              $t("updateScene.newVersion")
            }}</span>
          </div>
        </div>
        <div class="system-message-right bg-dark-red">
          <div
            class="system-message-right-item"
            v-for="number in 10"
            :key="number"
            :index="number"
          >
            <div class="system-message-right-item-avatar">
              <Avatar :url="getBaseAvatarUrl + '0.png'" size="90" />
            </div>
            <div class="system-message-right-item-text">
              {{
                $t("noficationWindow.notificationInviteChip", {
                  chipAmount: "",
                })
              }}
            </div>
            <span class="system-message-right-item-date">
              29.08.2022 - 19:50
            </span>
          </div>
        </div>
        <div class="ui-button ui-button-small-orange">
          <span>{{ $t("general.delete") }}</span>
        </div>
      </div>
      <div
        class="contact-us bg-content-red"
        v-if="currentPage === 'contact-us'"
      >
        <input
          class="contact-us-mail"
          type="text"
          v-model="email"
          :placeholder="$t('settingsWindow.pinCode.emailAddress')"
        />
        <input
          class="contact-us-title"
          type="text"
          v-model="title"
          :placeholder="$t('inboxContact.title')"
        />
        <input
          class="contact-us-text"
          type="text"
          v-model="content"
          :placeholder="$t('noficationWindow.sendMessage')"
        />
        <div class="ui-button ui-button-small-orange" @click="sendContactUs">
          {{ $t("general.send") }}
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StorageManager from "@/plugins/storage-manager";

export default {
  name: "NotificationWindow",
  components: {
    NotificationItem: () => import("./components/NotificationItem"),
    Avatar: () => import("@/components/Misc/Avatar"),
    BaseWindow: () => import("../BaseWindow"),
  },
  data() {
    return {
      currentPage: "",
      enoughChips: false,
      email: "",
      title: "",
      content: "",
    };
  },
  watch: {
    currentPage(val) {
      this.$store.state.modals.notificationWindowTabValue = val;
    },
    "$store.state.modals.notificationWindow": {
      handler(val) {
        if (val) {
          this.currentPage = "invitation";
          this.$adjust.trackEvent("NotificationWindowOn");
        }
      },
    },
  },
  computed: {
    ...mapState(["user", "jackpots", "scene"]),
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
      nAmISitting: "service/nAmISitting",
      texasRooms: `service/getTexasHoldemRooms`,
      omahaRooms: `service/getOmahaRooms`,
    }),
    ...mapGetters("friends", [
      "lastInviteRequest",
      "getFriendCurrentGame",
      "getFriendsById",
      "getInviteList",
    ]),
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
  },
  methods: {
    sendContactUs() {
      if (!this.email || !this.title || !this.content) {
        return;
      }

      this.$store.dispatch("friends/contactUsPost", { game: "CONTACT_US" });
    },
    inviteAction(value) {},
    closeWindow() {
      this.$store.commit("modals/hide", "notificationWindow");
    },
    joinGameFunc(joinRespond, userId) {
      if (!joinRespond) {
        this.$store.commit("friends/inviteListSplice", userId);
        return;
      }
      if (this.liveRoomAndGame) {
        if (
          (this.$constant.gameType.TEXAS === this.liveRoomAndGame.game ||
            this.$constant.gameType.OMAHA === this.liveRoomAndGame.game) &&
          this.getInviteRoom
        ) {
          if (
            this.user.chip < this.getInviteRoom.metadata.minBuy &&
            !this.user.vip.status
          ) {
            this.enoughChips = true;
            if (this.getRealPlatform !== "web" && this.scene === "Lobby") {
              //shop açılacak
              this.$store.commit("modals/show", "shop");
            }
            //Bu Item silinebilir
            this.show = false;
            this.$plugins.toast.show({
              text: this.$t("playerActions.enoughChips"),
              duration: 2500,
            });
            return;
          }
        }

        // nAmISitting
        // if (this.nAmISitting) {
        //   StorageManager.instance.remove({
        //     key: AppConstants.storageKeys.reconnect,
        //   });
        //   this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.send(
        //     "leave"
        //   );
        //   this.waitingEnterTable = {
        //     status: true,
        //     tableId: this.liveRoomAndGame.room,
        //     directedGame: this.liveRoomAndGame.game,
        //   };
        //   this.show = false;
        //   return;
        // }

        let friendCurrentGameRoomInfo = this.getFriendCurrentGame(userId);

        if (friendCurrentGameRoomInfo.room < 1) {
          this.$plugins.toast.show({
            text: this.$t("errors.userNotActiveGame"),
            duration: 2500,
          });
          return;
        }

        this.$store.commit("modals/hideModals", [
          "updateNotesWindow",
          "forceUpdateWindow",
        ]);

        switch (friendCurrentGameRoomInfo.game) {
          case this.$constant.gameType.TEXAS:
            this.$store.dispatch("service/joinPokerRoom", {
              roomId: friendCurrentGameRoomInfo.room,
              directedGame: friendCurrentGameRoomInfo.game,
            });
            break;
          case this.$constant.gameType.OMAHA:
            this.$store.dispatch("service/joinPokerRoom", {
              roomId: friendCurrentGameRoomInfo.room,
              directedGame: friendCurrentGameRoomInfo.game,
            });
            break;
          case this.$constant.gameType.BLACKJACK:
            this.$adjust.trackEvent("ClickBlackjack");
            this.$store.dispatch("service/joinToBlackjackRoom");
            break;
          case this.$constant.gameType.TURN_LEGENDS:
            this.$adjust.trackEvent("ClickTurnLegends");
            this.$emit("slotPlay", "turnLegends");
            break;
          case this.$constant.gameType.BAMBOO_PANDA:
            this.$adjust.trackEvent("ClickBamboPanda");
            this.$emit("slotPlay", "bambooPanda");
            break;
          case this.$constant.gameType.FAFAFA:
            this.$adjust.trackEvent("ClickFafafa");
            this.$emit("slotPlay", "fafafa");
            break;
          case this.$constant.gameType.OLYMPUS:
            this.$adjust.trackEvent("ClickTurnOfOlympus");
            this.$emit("slotPlay", "olympus");
            break;

          default:
            console.log("Game not found !");
            break;
        }

        this.$store.commit("friends/inviteListSplice", userId);
      }
    },
    liveRoomAndGame() {
      if (this.inviteRequest && userId) {
        const roomAndGame = this.getFriendCurrentGame(userId);

        if (roomAndGame) {
          return roomAndGame;
        }
        return "";
      }
      return "";
    },
    getInviteRoom() {
      if (this.liveRoomAndGame && this.liveRoomAndGame.room) {
        if (this.$constant.gameType.BLACKJACK === this.liveRoomAndGame.game) {
          return this.liveRoomAndGame.room;
        } else if (
          this.$constant.gameType.TEXAS === this.liveRoomAndGame.game
        ) {
          return this.texasRooms.find(
            (room) => room.id == this.liveRoomAndGame.room
          );
        } else if (
          this.$constant.gameType.OMAHA === this.liveRoomAndGame.game
        ) {
          return this.omahaRooms.find(
            (room) => room.id == this.liveRoomAndGame.room
          );
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  width: 100%;
  height: 92%;
  background-color: #370b11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  &-title {
    height: 8%;
    width: 100%;
    font-size: 48px;
    text-shadow: 0 3px 5px #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-header {
    height: 10%;
    width: 99%;
    display: flex;
    flex-direction: row;
    color: #d1717f;
    font-size: 36px;
    border: none;
    &-item {
      width: 295px;
      height: 99px;
      text-shadow: 0 3px 5px #000000;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 40px;
      position: relative;
      .slot-line {
        background: url("~@/assets/images/profile/slot-line.png") no-repeat 50%
          50%;
        background-size: contain;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
      }
    }
    &-item:first-child {
      width: 271px;
      height: 99px;
    }
    .profile-sprite {
      color: #ffffff;
    }
  }
  &-content {
    width: 99%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
    .line {
      position: relative;
      margin: 10px 0;
    }
    .invitation-no-data {
      width: 100%;
      height: 50%;
      &-text {
        font-size: 34px;
        text-shadow: 0 3px 5px #000000;
      }
    }
  }
  .system-message {
    width: 99%;
    height: 90%;
    border-radius: 20px;
    display: flex;
    font-size: 40px;
    border: none;
    &-left {
      width: 25%;
      height: 82%;
      margin: 47px 20px;
      border-radius: 15px;
      overflow-y: scroll;
      box-shadow: 0px -1px 5px #000000;
      &-item {
        width: 90%;
        min-height: 130px;
        font-size: 40px;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: rgb(141, 32, 49);
        background: linear-gradient(
          0deg,
          rgba(141, 32, 49, 1) 0%,
          rgba(179, 53, 71, 1) 100%
        );
        margin: 20px 20px;
        &-text {
          font-size: 40px;
        }
      }
    }
    &-right {
      width: 80%;
      height: 82%;
      margin: 47px 20px;
      border-radius: 15px;
      box-shadow: 0px -1px 5px #000000;
      overflow-y: scroll;
      &-item {
        margin: 20px 20px 50px 20px;
        width: 1341px;
        min-height: 110px;
        position: relative;
        background-color: #791d2a;
        border-radius: 15px;

        display: flex;
        flex-direction: row;
        &-avatar {
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-date {
          position: absolute;
          right: 5px;
          bottom: -32px;
          font-size: 32px;
          color: #ffffff;
          text-shadow: 0 3px 5px #000000;
        }
      }
    }
    .ui-button {
      position: absolute;
      bottom: 18px;
      right: 30px;
    }
  }
  .contact-us {
    width: 99%;
    height: 88%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 40px;
    &-mail {
      width: 700px;
      position: relative;
      top: 100px;

      height: 80px;
      border-radius: 100px;
      background-color: #d5b3b8;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px -1px 3px #000000;
      padding-top: 10px;
      &::placeholder {
        font-size: 34px;
        text-align: center;
      }
    }
    &-title {
      width: 900px;
      position: relative;
      top: 150px;
      height: 90px;
      border-radius: 40px;
      background-color: #d5b3b8;
      box-shadow: 0px -1px 3px #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      &::placeholder {
        font-size: 34px;
        text-align: center;
      }
    }
    &-text {
      width: 1100px;
      position: relative;
      top: 200px;
      height: 220px;
      border-radius: 40px;
      background-color: #d5b3b8;
      box-shadow: 0px -1px 3px #000000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &::placeholder {
        font-size: 34px;
        text-align: center;
      }
    }
    .ui-button {
      position: relative;
      top: 300px;
    }
  }
}
</style>
